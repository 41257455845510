// 外层地图
export const outmap = {
  id: 1,
  z: 1,
  zlevel: 0,
  left: 'center',
  itemStyle: {
    areaColor: '#15192B',
    borderWidth: 3,
    borderColor: '#D5E9FF',
    shadowColor: 'rgba(36, 131, 255, 1)',
    shadowBlur: 10
  },
  emphasis: {
    itemStyle: {
      areaColor: '#15192B'
    }
  }
}

// 内层地图
export const inmap = {
  type: 'map',
  z: 3,
  zlevel: 0,
  left: 'center',
  label: {
    show: true,
    color: '#ECECEC',
    fontSize: '10'
  },
  itemStyle: {
    areaColor: '#15192B',
    borderColor: '#FDFEFF',
    borderWidth: 0.5,
    borderType: 'solid'
  },
  emphasis: {
    label: {
      show: true,
      color: '#ECECEC',
      fontSize: '10'
    },
    itemStyle: {
      color: '#ECECEC',
      areaColor: '#15192B'
    }
  }
}

// 分段统计
export const overviewdata = {
  type: 'piecewise',
  splitNumber: 3,
  min: 0,
  max: 2500,
  itemWidth: 12,
  itemHeight: 12,
  align: 'left',
  z: 3,
  zlevel: 0,
  left: 0,
  bottom: 0,
  padding: 16,
  borderColor: 'rgba(18, 24, 61, 0.6)',
  borderWidth: 2,
  textStyle: {
    color: '#fff',
    style: 'italic'
  },
  hoverLink: false,
  pieces: [
    { min: 0, max: 0, label: '暂无门店', color: '#15192B' },
    { min: 1, max: 10, label: '门店数>1家', color: '#001D4F' },
    { min: 11, max: 20, label: '门店数>10家', color: '#2007A1' },
    { min: 21, max: 50, label: '门店数>20家', color: '#6E03A0' },
    { min: 51, label: '门店数>50家', color: '#BA0549' }
  ]
}

// 提示框
export const tooltip = {
  trigger: 'item',
  triggerOn: 'none',
  confine: true,
  backgroundColor: 'rgba(8, 35, 103, 0.6)',
  borderColor: '#2483FF',
  borderWidth: 1,
  padding: [2, 4],
  position: 'top',
  formatter: d => {
    return d.name
  }
}

// 动点
export const flashDot = {
  name: '门店分布点-动态',
  type: 'effectScatter',
  symbolSize: 5,
  z: 7,
  zlevel: 0,
  coordinateSystem: 'geo',
  rippleEffect: {
    period: 2,
    scale: 3,
    brushType: 'fill'
  },
  itemStyle: {
    color: '#FFF',
    borderColor: '#FFF'
  }
}

// 静点
export const staticDot = {
  name: '门店分布点',
  type: 'scatter',
  symbolSize: 6,
  z: 5,
  zlevel: 0,
  coordinateSystem: 'geo',
  itemStyle: {
    color: '#FFF',
    borderColor: '#FFF'
  }
}

// 放射线
export const moveLines = {
  name: '门店放射线',
  type: 'lines',
  z: 10,
  zlevel: 1,
  effect: {
    show: true,
    period: 4,
    trailLength: 0.6,
    color: '#2EDBFF',
    symbolSize: 2
  },
  lineStyle: {
    color: '#2EDBFF',
    width: 1,
    type: 'solid',
    opacity: 0,
    curveness: 0.5
  }
}
