<template>
  <div class="map">
    <div class="total">
      <div class="all">
        <p class="font-12 color-2edbff">{{overview.name}}门店总数</p>
        <!-- <span class="font-22 color-fff">{{overview.total}}</span> -->
        <span class="font-22 color-fff">368</span>
        <span class="font-12 color-fff">家</span>
      </div>
      <div class="bread-crumbs color-fff font-12">
        <template v-for="(item, index) in breadcrumb">
          <p
            v-if="index < 3"
            :key="index"
            :class="[
              level === index ? 'active' : 'to'
            ]"
            @click="toBefore(index)"
          >
            {{item.name}}
          </p>
        </template>
      </div>
    </div>
    <div class="mua-map" ref="map"></div>
  </div>
</template>

<script>
import echarts from 'echarts'
import { EventBus } from '@/utils/eventBus.js'
import { outmap, inmap, overviewdata, tooltip, flashDot, staticDot, moveLines } from './setting'
export default {
  name: 'MuaMap',
  props: {
    level: {
      default: 0,
      type: Number,
      required: true
    },
    breadcrumb: {
      type: Array,
      required: true
    }
  },
  data() {
    return {
      chart: null,
      overview: {
        name: '全国',
        total: 0
      }
    }
  },
  watch: {
    level: {
      handler: 'getMapData',
      immediate: true
    }
  },
  mounted() {
    // 绘制地图
    this.chart = echarts.init(this.$refs.map)
    this.$nextTick(() => {
      EventBus.$on('resize', () => {
        this.chart.resize()
      })
      this.chart.on('mousemove', 'series.effectScatter', data => {
        if (this.level <= 1) return
        this.chart.dispatchAction({
          type: 'showTip',
          name: data.data.name,
          seriesIndex: 1
        })
      })
      this.chart.on('click', async data => {
        if (this.level === 2) {
          if (data.componentSubType === 'effectScatter') {
            const lastItem = this.breadcrumb[this.breadcrumb.length - 1]
            if (Object.prototype.hasOwnProperty.call(lastItem, 'shopid')) {
              // 店点到店
              const returnArr = this.breadcrumb.slice(0, this.breadcrumb.length - 1)
              this.$emit('update:breadcrumb', [...returnArr, {
                name: data.data.name,
                shopid: data.data.shopid
              }])
            } else {
              // 市点到店
              this.$emit('update:breadcrumb', [...this.breadcrumb, {
                name: data.data.name,
                shopid: data.data.shopid
              }])
            }
          }
          // 店退回市
          if (data.componentSubType === 'map') {
            const lastItem = this.breadcrumb[this.breadcrumb.length - 1]
            if (Object.prototype.hasOwnProperty.call(lastItem, 'shopid')) {
              const returnArr = this.breadcrumb.slice(0, this.breadcrumb.length - 1)
              this.$emit('update:breadcrumb', returnArr)
            }
            this.chart.dispatchAction({ type: 'hideTip' })
          }
        }
        // 没有数据就不往下点了
        if (!data.data || !data.data.value) return
        if (data.componentSubType === 'map') {
          this.$emit('update:breadcrumb', [...this.breadcrumb, {
            name: data.data.name,
            map: data.data.name,
            adcode: data.data.adcode
          }])
          this.$emit('update:level', this.level + 1)
        }
      })
    })
  },
  beforeDestroy() {
    EventBus.$off('resize')
  },
  methods: {
    // 获得地图数据
    async getMapData() {
      const adcode = this.breadcrumb[this.breadcrumb.length - 1].adcode
      const map = this.breadcrumb[this.breadcrumb.length - 1].map
      const name = this.breadcrumb[this.breadcrumb.length - 1].name
      const params = {
        adcode,
        level: this.level
      }
      const {data} = await this.$https.get('map', { params })

      this.overview = {
        name,
        total: data.shop_count
      }

      const mapdata = await this.$https.get(data.geojson)
      // 注册地图
      echarts.registerMap(map, mapdata)

      let mapColor = [] // 地图着色
      let fDot = [] // 动点 flashDot
      let sDot = [] // 静点 staticDot
      let lines = [] // 放射线
      data.map_data.forEach(item => {
        mapColor.push({
          name: item.name,
          value: item.shop_count,
          adcode: item.adcode
        })
        if (this.level === 0) {
          // 第一级点太多，所以part_shops是动点的数量，只在第一级使用
          item.part_shops.forEach(e => {
            lines.push({ coords: e })
            fDot.push({
              visualMap: false,
              value: e[1] // e[1]终点，e[0]是起始点都是一样的，定位在杭州
            })
          })
        }
        item.shops.forEach(e => {
          const dot = {
            shopid: e.id,
            visualMap: false,
            name: e.name,
            value: e.value.split(',')
          }
          if (this.level === 0) {
            sDot.push(dot)
          } else {
            fDot.push(dot)
          }
        })
      })

      const option = {
        geo: {
          map,
          zoom: this.level ? 1.2 : 1.5,
          top: this.level ? 'center' : '24%',
          ...outmap
        },
        visualMap: {
          show: this.level < 2,
          ...overviewdata
        },
        tooltip: tooltip,
        series: [
          {
            map,
            zoom: this.level ? 1.2 : 1.5,
            top: this.level ? 'center' : '24%',
            data: mapColor,
            ...inmap
          },
          {
            data: fDot,
            ...flashDot
          },
          {
            data: sDot,
            ...staticDot
          },
          {
            data: lines,
            ...moveLines
          }
        ]
      }
      this.chart.clear()
      this.chart.setOption(option)
    },
    toBefore (index) {
      this.chart.dispatchAction({ type: 'hideTip' })
      if (index === this.level) return
      this.$emit('update:breadcrumb', this.breadcrumb.slice(0, index + 1))
      this.$emit('update:level', index)
    }
  }
}
</script>

<style scoped lang="less">
.map {
  position: relative;
  width: 100%;
  height: 100%;
  .total {
    position: absolute;
    left: 0;
    top: 0;
    z-index: 9;
    p {
      margin: 0;
    }
    .all {
      position: relative;
      padding-left: 11px;
      &::before {
        position: absolute;
        content: '';
        left: 0;
        top: 50%;
        transform: translateY(-50%);
        width: 3px;
        height: 40px;
        background-image: linear-gradient(180deg, #2483FF 0%, #01D18A 100%);
      }
    }
    .bread-crumbs {
      display: flex;
      margin: 8px 0 0;
      cursor: pointer;
      .active {
        position: relative;
        overflow: hidden;
        color: #2EDBFF;
        &:hover::before {
          animation: 400ms linear flash;
        }
        &::before {
          content: '';
          position: absolute;
          left: 110%;
          top: -10%;
          width: 20%;
          height: 150%;
          transform: rotate(30deg);
          background-color: #FFF;
          opacity: .4;
        }
      }
      .to {
        position: relative;
        overflow: hidden;
        &:hover::before {
          animation: 400ms linear flash;
        }
        &::before {
          content: '';
          position: absolute;
          left: 110%;
          top: -10%;
          width: 20%;
          height: 150%;
          transform: rotate(30deg);
          background-color: #2EDBFF;
          opacity: .4;
        }
        &::after {
          content: '>';
          padding-right: .25rem;
          font-size: inherit;
          color: inherit;
        }
      }
    }
  }
  .mua-map {
    width: 100%;
    height: 100%;
  }
}
@keyframes flash {
  from {
    left: -10%;
  }
  to {
    left: 100%;
  }
}
</style>
