<template>
  <div class="dataview">
    <div class="head flex-between">
      <div>
        <span class="color-fff font-18">{{lastBread.name + $translate[name].ch}}</span>
        <span class="color-2edbff font-10">{{$translate[name].en}}</span>
      </div>
      <span class="font-28 color-2edbff">{{$translate[name].index}}</span>
    </div>
    <div>
      <mua-button-group>
        <mua-button :type="option.type === 0 ? 'active' : ''" @click="chooseOption(0)">累计</mua-button>
        <mua-button :type="option.type === 1 ? 'active' : ''" @click="chooseOption(1)">分时</mua-button>
      </mua-button-group>
      <mua-radio
        class="radio"
        :label="item.value"
        v-model="radio"
        v-for="(item, index) in head"
        :key="index"
      >
        {{item.label}}
      </mua-radio>
    </div>
    <mua-line
      class="line-chart"
      :data="body"
    />
  </div>
</template>

<script>
export default {
  name: 'LevelTwoChart',
  props: {
    head: Array, // 筛选框
    body: Array,
    name: {
      type: String,
      required: true
    },
    option: {
      type: Object,
      default: () => {
        return {
          type: 0,
          radio: 'push_num'
        }
      }
    },
    breadcrumb: Array
  },
  computed: {
    radio: {
      get() {
        return this.option.radio
      },
      set(val) {
        const r = Object.assign({}, this.option, {radio: val})
        this.$emit('update:option', r)
        return val
      }
    },
    lastBread() {
      return this.breadcrumb[this.breadcrumb.length - 1]
    }
  },
  methods: {
    chooseOption(type) {
      const r = Object.assign({}, this.option, {type})
      this.$emit('update:option', r)
    }
    // radio有个change事件的
    // radioChange() {
    //   const r = Object.assign({}, this.option, {radio: this.radio})
    //   this.$emit('update:option', r)
    // }
  }
}
</script>

<style scoped lang="less">
.dataview {
  padding: 0.5rem 1rem;
  width: 100%;
  border: 2px solid #3B69AE;
  background-image: linear-gradient(180deg, rgba(18, 24, 61, 0.6) 0%, rgba(18, 22, 69, 0.6) 100%);
  box-shadow: inset 0 0 .75rem rgba(100, 200, 255, .5);
  box-sizing: border-box;
  .head {
    padding-bottom: 4px;
    span {
      display: block;
    }
  }
  .radio {
    margin-left: 1rem;
  }
  .line-chart {
    width: 100%;
    height: 200px;
  }
}
</style>
