<template>
  <div class="region">
    <div class="head flex-between">
      <div>
        <span class="color-fff font-18">{{$translate[name].ch}}</span>
        <span class="color-2edbff font-10">{{$translate[name].en}}</span>
      </div>
      <div class="flex-center" v-if="warn">
        <span class="color-fff font-10">{{warn}}不达标率：</span>
        <div class="standard flex-center">
          <input class="color-ff001a" type="number" v-model="standardValue" :placeholder="standardV" >
        </div>
        <div class="confirm font-10 color-fff cursor" @click="sortAgain">确定</div>
      </div>
    </div>
    <mua-table :head="head" :data="body" background @click="tableTdClick"></mua-table>
  </div>
</template>

<script>
export default {
  name: 'YingYun',
  props: {
    warn: {
      default: '',
      type: String
    },
    head: {
      default: () => {
        return []
      },
      required: true,
      type: Array
    },
    body: {
      default: () => {
        return []
      },
      required: true,
      type: Array
    },
    name: {
      default: '',
      type: String,
      required: true
    }
  },
  data() {
    return {
    }
  },
  computed: {
  },
  watch: {
  },
  methods: {
    tableTdClick(e) {
      let data = Object.assign({}, e, {
        tableName: this.name,
        tableIndex: this.$translate[this.name].index
      })
      this.$emit('showDetail', data)
    }
  }
}
</script>

<style scoped lang="less">
.region {
  padding: 0.5rem 1rem;
  width: 100%;
  border: 2px solid #3B69AE;
  background-image: linear-gradient(180deg, rgba(18, 24, 61, 0.6) 0%, rgba(18, 22, 69, 0.6) 100%);
  box-shadow: inset 0 0 .75rem rgba(100, 200, 255, .5);
  box-sizing: border-box;
  .head {
    padding-bottom: 4px;
    span {
      display: block;
    }
  }
  .standard {
    &::after {
      content: '%';
      color: #FFF;
      font-size: 0.75rem;
    }
    input {
      width: 40px;
      border-radius: 2px;
      border: 1px solid rgba(0,252,255,0.6);
      background-image: linear-gradient(180deg, #0A308D 0%, #021749 100%);
      box-sizing: border-box;
      text-align: right;
    }
    ::-webkit-input-placeholder {
			color: #FF001A;
		}
  }
  .confirm {
    margin-left: 10px;
    padding: 0 8px;
    background-image: linear-gradient(270deg,#01D18A 0%, #2483FF 100%);
    border-radius: 2px;
  }
}
</style>
