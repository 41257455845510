<template>
  <div class="siderbar color-fff">
    <div class="font-18 chtitle">{{$translate['siderbar'][level]['ch']}}</div>
    <div class="font-10 entitle color-2edbff">{{$translate['siderbar'][level]['en']}}</div>
    <div class="scroll" v-show="hasData">
      <div
        class="shop-item font-12"
        :class="item.open_status === 0 && 'rest'"
        v-for="(item, index) in rank"
        :key="index"
        @click="showDetail(item)"
      >
        <span class="sort" v-show="!isEnd">NO.{{index + 1}}</span>
        <span
          :class="[
            'nowrap',
            !isEnd ? 'sort-width' :
            item.open_status === 0 ? 'rest-width' : ''
          ]"
        >
          {{item.name}}
        </span>
        <span v-if="!isEnd">({{item.open_value + '/' + item.value}}家)</span>
        <span v-if="item.open_status === 0">(已关店)</span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'SiderBar',
  props: {
    level: {
      default: 0,
      type: Number,
      required: true
    },
    breadcrumb: {
      type: Array,
      required: true
    }
  },
  data() {
    return {
      rank: []
    }
  },
  watch: {
    level: {
      handler: 'getData',
      immediate: true
    }
  },
  computed: {
    isEnd() {
      return this.level === 2
    },
    hasData() {
      return this.rank.length !== 0
    }
  },
  methods: {
    async getData() {
      const params = {
        level: this.level,
        adcode: this.breadcrumb[this.level].adcode
      }
      this.rank = []
      const {data} = await this.$https.get('rank', {params})
      this.rank = data.filter(item => item.type === 1)
      this.rank = this.rank[0].list
      if (this.level === 2) {
        this.rank.forEach(item => {
          item.name = item.shop_name
        })
        this.rank.sort((a, b) => {
          return b.open_status - a.open_status
        })
      }
    },
    showDetail(value) {
      if (!this.isEnd && +value.open_value !== 0) {
        this.$emit('update:breadcrumb', [...this.breadcrumb, {
          name: value.name,
          map: value.name,
          adcode: value.adcode
        }])
        this.$emit('update:level', this.level + 1)
      }
      if (+value.open_status === 1) {
        this.$emit('update:breadcrumb', [...this.breadcrumb, {
          name: value.name,
          shopid: value.id
        }])
      }
    }
  }
}
</script>

<style scoped lang="less">
.siderbar {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  border: .75rem solid transparent;
	border-image: url('http://datav.4008737773.com/static_img/border.png') 5%;
  box-sizing: border-box;
  .chtitle {
    padding-top: 0.375rem;
  }
  .entitle {
    padding-bottom: 1.5rem;
  }
  .scroll {
    flex: 1;
    overflow-y: auto;
    &::-webkit-scrollbar {
      width: 0.25rem;
    }
    &::-webkit-scrollbar-thumb {
      background-color: #2EDBFF;
      border-radius: 0.25rem;
    }
    .shop-item {
      position: relative;
      width: 95%;
      padding-bottom: 0.375rem;
      margin-bottom: 1rem;
      cursor: pointer;
      &:hover {
        color: #2EDBFF;
      }
      &::before {
        position: absolute;
        content: '';
        left: 0;
        bottom: 0;
        width: 100%;
        height: 1px;
        background-image: linear-gradient(270deg, #01D18A 0%, #2483FF 100%);
      }
      .sort {
        display: inline-block;
        width: 2.5rem;
        color: #2483FF;
      }
      .sort-width {
        width: 2.25rem!important;
      }
      .rest-width {
        width: 5.625rem!important;
      }
      .nowrap {
        width: 100%;
        display: inline-block;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
        vertical-align: text-bottom;
      }
    }
    .rest {
      color: #999;
      &:hover {
        color: #999;
      }
      &::before {
        background-image: none;
        background-color: #999;
      }
    }
  }
}
</style>
