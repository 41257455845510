/**
 * @param {*} data 接口返回的数据处理
 * @return {*} back返回值是一个对象，格式如下
 * back = {
 *  name1: [],
 *  name2: [],
 *  ...
 * }
 * name 父组件定义所需的数据 $translate的键值 三者互相对应
 */
import { strip, round, times } from '@/utils/numberPrecision'
function cityRank(data) {
  const source = JSON.parse(JSON.stringify(data))
  let back = {
    orders: [],
    sales: [],
    profit: [],
    exposure: [],
    shopConversion: [],
    orderConversion: [],
    subsidy: []
  }
  source.forEach(item => {
    let sample = {
      code: item.code,
      name: item.name,
      canClick: item.can_click
    }
    if (item.can_click) {
      sample.provincecode = item.provincecode
      sample.provincename = item.provincename
    }

    item = dealStrip(item)

    // 订单数
    back.orders.push(Object.assign({}, sample, {
      value: item.orders,
      today: item.orders_today,
      last: item.orders_last
    }))
    // 销售额
    back.sales.push(Object.assign({}, sample, {
      value: item.sales,
      today: round(item.sales_today, 2, true),
      last: round(item.sales_last, 2, true)
    }))
    // 毛利
    back.profit.push(Object.assign({}, sample, {
      value: item.profits,
      today: round(item.profits_today, 2, true),
      last: round(item.profits_last, 2, true)
    }))
    // 曝光人数
    back.exposure.push(Object.assign({}, sample, {
      value: item.push_num,
      today: item.push_num_today,
      last: item.push_num_last
    }))
    // 入店转化率 - 进店转化差值
    back.shopConversion.push(Object.assign({}, sample, {
      value: times(item.visit_rates, 100),
      today: round(times(item.visit_rates_today, 100), 2, true) + '%',
      last: round(times(item.visit_rates_last, 100), 2, true) + '%'
    }))
    // 下单转换率
    back.orderConversion.push(Object.assign({}, sample, {
      value: times(item.place_order, 100),
      today: round(times(item.place_order_today, 100), 2, true) + '%',
      last: round(times(item.place_order_last, 100), 2, true) + '%',
    }))
    // 商补
    back.subsidy.push(Object.assign({}, sample, {
      value: item.subside,
      today: round(item.subside_today, 2, true) + '%',
      last: round(item.subside_last, 2, true) + '%'
    }))
  })

  for (const key in back) {
    if (Object.prototype.hasOwnProperty.call(back, key)) {
      const element = back[key]
      element.sort((a, b) => {
        return b.value - a.value
      })
      element.forEach((item, index) => {
        item.$index = index + 1
      })
    }
  }
  return back
}

function operate(data) {
  const source = JSON.parse(JSON.stringify(data))
  let back = []
  const date = {
    today: '今天',
    yesterday: '昨日',
    last_week: '上周'
  }
  for (const key in source) {
    const value = source[key]
    for (const ele in value) {
      value['time'] = date[key]
      if (Object.prototype.hasOwnProperty.call(value, ele)) {
        if (typeof value[ele] === 'number') value[ele] = round(value[ele], 2)
      }
    }
    back.push(value)
  }
  return back
}

function flow(data, type) {
  const now = new Date()
  let source = JSON.parse(JSON.stringify(data))
  for (const key in source) {
    let element = source[key]
    if (type === 'pf') {
      element.forEach(item => {
        item = dealStrip(item)
        item.place_order = times(item.place_order , 100)
        item.visit_rates = times(item.visit_rates , 100)
        item.place_order_all = times(item.place_order_all , 100)
        item.visit_rates_all = times(item.visit_rates_all , 100)
      })
    }
    if (type === 'bf') {
      element.forEach(item => item = dealStrip(item))
      if (key === 'today') {
        source[key] = element.filter(item => new Date(item.end_time) < now)
      }
    }
  }
  return source
}

function dealStrip(obj) {
  const isEmptyObject = obj => {
    let name
    for (name in obj) {
      return false
    }
    return true
  }
  if (isEmptyObject(obj)) return obj
  // 处理浮点数
  for (const key in obj) {
    if (Object.prototype.hasOwnProperty.call(obj, key) && typeof obj[key] === 'number') {
      obj[key] = round(strip(obj[key]), 2)
    }
  }
  return obj
}

export { cityRank, operate, flow }