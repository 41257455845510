<template>
  <div class="dataview">
    <div class="head flex-between">
      <div>
        <span class="color-fff font-18">{{$translate[name].ch}}</span>
        <span class="color-2edbff font-10">{{$translate[name].en}}</span>
      </div>
      <span class="font-28 color-2edbff">{{$translate[name].index}}</span>
    </div>
    <mua-table :head="head" :data="body" background @click="tableTdClick">
      <template v-slot:sort>
        <span class="sort" @click="sort">
          <img class="sort-icon" :src="status ? up : down" alt="sort">
        </span>
      </template>
    </mua-table>
  </div>
</template>

<script>
export default {
  name: 'LevelZeroOne',
  props: {
    tableHead: {
      default: () => {
        return []
      },
      required: true,
      type: Array
    },
    tableBody: {
      default: () => {
        return []
      },
      required: true,
      type: Array
    },
    name: {
      default: '',
      type: String,
      required: true
    }
  },
  data() {
    return {
      status: true,
      up: require('@/assets/table_up.png'),
      down: require('@/assets/table_down.png')
    }
  },
  computed: {
    head() {
      return this.tableHead
    },
    body() {
      let body = JSON.parse(JSON.stringify(this.tableBody))
      body.sort((a, b) => {
        return this.status ? b.value - a.value : a.value - b.value
      })
      return body
    }
  },
  watch: {
    tableHead() {
      this.status = true
    },
    tableBody() {
      this.status = true
    }
  },
  methods: {
    sort() {
      this.status = !this.status
    },
    tableTdClick(e) {
      this.$emit('toNext', e)
    }
  }
}
</script>

<style scoped lang="less">
.dataview {
  padding: 0.5rem 1rem;
  width: 100%;
  border: 2px solid #3B69AE;
  background-image: linear-gradient(180deg, rgba(18, 24, 61, 0.6) 0%, rgba(18, 22, 69, 0.6) 100%);
  box-shadow: inset 0 0 .75rem rgba(100, 200, 255, .5);
  box-sizing: border-box;
  .head {
    padding-bottom: 4px;
    span {
      display: block;
    }
  }
  .sort {
    display: inline-block;
    height: 0.75rem;
    line-height: 0.75rem;
    padding: 0 2px;
    margin-left: 2px;
    border-radius: 50%;
    background-image: linear-gradient(270deg, #07866B 0%, #1C5AB3 100%);
    cursor: pointer;
    .sort-icon {
      width: 6px;
      height: 7px;
    }
  }
}
</style>
