<template>
  <div class="dataview">
    <div class="head flex-between">
      <div>
        <span class="color-fff font-18">{{$translate[name].ch}}</span>
        <span class="color-2edbff font-10">{{$translate[name].en}}</span>
      </div>
      <span class="font-28 color-2edbff">{{$translate[name].index}}</span>
    </div>
    <mua-table :head="head" :data="body" border></mua-table>
  </div>
</template>

<script>
export default {
  name: 'LevelTwoTable',
  props: {
    head: {
      default: () => {
        return []
      },
      required: true,
      type: Array
    },
    body: {
      default: () => {
        return []
      },
      required: true,
      type: Array
    },
    name: {
      default: '',
      type: String,
      required: true
    }
  }
}
</script>

<style scoped lang="less">
.dataview {
  padding: 0.5rem 1rem;
  width: 100%;
  border: 2px solid #3B69AE;
  background-image: linear-gradient(180deg, rgba(18, 24, 61, 0.6) 0%, rgba(18, 22, 69, 0.6) 100%);
  box-shadow: inset 0 0 .75rem rgba(100, 200, 255, .5);
  box-sizing: border-box;
  .head {
    padding-bottom: 4px;
    span {
      display: block;
    }
  }
  .line-chart {
    width: 100%;
    height: 200px;
  }
}
</style>
