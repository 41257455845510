/**
 * @param {*} data 接口返回的数据处理
 * @return {*} back返回值是一个对象，格式如下
 * back = {
 *  name1: [],
 *  name2: [],
 *  ...
 * }
 * name 父组件定义所需的数据 $translate的键值 三者互相对应
 */

import { /*strip,*/ round, times } from '@/utils/numberPrecision'
function dealRegion(data, type) {
  const source = JSON.parse(JSON.stringify(data))
  const back = {
    allRank: [],
    badEva: [],
    urgOrd: [],
    foodSafe: [],
    proErr: [],
    score: [],
    storeAno: [],
    humanEff: [],
    checkStock: []
  }

  source.forEach(item => {
    let data = {
      region: item.name,
      code: item.code || 0
    }
    back.allRank.push(Object.assign({
      chapin: round(times(+item.chapin, 100), 2, true),
      cuidan: round(times(+item.cuidan, 100), 2, true),
      safe: round(times(+item.safe, 100), 2, true),
      sort: item.level
    }, data))
    back.badEva.push(Object.assign({
      chapin_count: +item.chapin_count,
      chapin: round(times(+item.chapin, 100), 2, true),
      sort: +item.chapin
    }, data))
    back.urgOrd.push(Object.assign({
      cuidan_count: +item.cuidan_count,
      cuidan: round(times(+item.cuidan, 100), 2, true),
      sort: +item.cuidan
    }, data))
    back.foodSafe.push(Object.assign({
      safe_count: +item.safe_count,
      safe: round(times(+item.safe, 100), 2, true),
      sort: +item.safe
    }, data))
    back.proErr.push(Object.assign({
      make_error_count: +item.make_error_count,
      make_error: round(times(+item.make_error, 100), 2, true),
      sort: +item.make_error
    }, data))
    back.score.push(Object.assign({
      mt_score: round(+item.mt_score, 2, true),
      ele_score: round(+item.ele_score, 2, true),
      score_com: round(times(+item.score_com, 100), 2, true),
      sort: +item.score_com
    }, data))
    back.storeAno.push(Object.assign({
      excp_count: round(+item.excp_count,0),
      excp: round(times(+item.excp, 100), 1, true),
      sort: +item.excp
    }, data))
    back.humanEff.push(Object.assign({
      gongzi: round(+item.gongzi,0),
      renxiao: round(times(+item.renxiao, 100), 1, true),
      sort: +item.renxiao
    }, data))
    back.checkStock.push(Object.assign({
      shicai: round(times(+item.shicai, 100), 2, true),
      baocai: round(times(+item.baocai, 100), 2, true),
      biaozhun_maoli: round(times(+item.biaozhun_maoli, 100), 2, true),
      yingyun_maoli: round(times(+item.yingyun_maoli, 100), 2, true),
      sort: +item.yingyun_maoli
    }, data))
  })

  for (const key in back) {
    let element = back[key]
    element.sort((a, b) => {
      return b.sort - a.sort
    })
    let allIndex = -1 // 总计索引，总计放到数组第一位
    element.forEach((item, index) => {
      if (item.region === '总计') allIndex = index
    })
    if (allIndex !== -1) {
      const i = element.splice(allIndex, 1)
      back[key].unshift(i[0])
    }
    element.forEach((item, index) => {
      item.$index = index + 1
    })
    if (type === 'dialog') { // 弹框最后一项名称改为‘总计’
      back[key][back[key].length -1].region = '总计'
    }
  }

  return back
}

export { dealRegion }