<template>
  <main class="main">
    <div class="siderbar-left">
      <sider-bar :level.sync="level" :breadcrumb.sync="breadcrumb" />
    </div>
    <div class="content">
      <Map
        :level.sync="level"
        :breadcrumb.sync="breadcrumb"
      />
    </div>
    <div class="siderbar-right" mualoading="true">
      <div class="right-search">
        <template v-if="roleType === 0">
          <mua-button-group>
            <mua-button :type="operateOptions.platType === 0 ? 'active' : ''" @click="chooseOption(0, 0)">全部</mua-button>
            <mua-button :type="operateOptions.platType === 9 ? 'active' : ''" @click="chooseOption(0, 9)">美团</mua-button>
            <mua-button :type="operateOptions.platType === 8 ? 'active' : ''" @click="chooseOption(0, 8)">饿了么</mua-button>
          </mua-button-group>
          <mua-button-group>
            <mua-button :type="operateOptions.compareType === 1 ? 'active' : ''" @click="chooseOption(1, 1)">同比</mua-button>
            <mua-button :type="operateOptions.compareType === 2 ? 'active' : ''" @click="chooseOption(1, 2)">环比</mua-button>
          </mua-button-group>
        </template>
        <template v-else>
          <mua-button-group>
            <mua-button :type="regionOptions.dateType === 1 ? 'active' : ''" @click="changeOption(0, 1)">昨日</mua-button>
            <mua-button :type="regionOptions.dateType === 2 ? 'active' : ''" @click="changeOption(0, 2)">本月</mua-button>
          </mua-button-group>
          <mua-button-group>
            <mua-button :type="regionOptions.circleType === 2 ? 'active' : ''" @click="changeOption(1, 2)">大区</mua-button>
            <mua-button :type="regionOptions.circleType === 1 ? 'active' : ''" @click="changeOption(1, 1)">片区</mua-button>
          </mua-button-group>
        </template>
        <mua-button-group>
          <mua-button @click="refresh">
            <img class="refresh-image" src="@/assets/refresh.png" alt="refresh" >
            更新时间 | {{refreshTime}}
          </mua-button>
        </mua-button-group>
        <mua-button-group class="right" v-if="isAdmin">
          <mua-button :type="roleType === 0 ? 'active' : ''" @click="chooseRole(0)">运营</mua-button>
          <mua-button :type="roleType === 1 ? 'active' : ''" @click="chooseRole(1)">营运</mua-button>
        </mua-button-group>
      </div>
      <div class="view">
        <template v-if="roleType === 0">
          <template v-if="level <= 1">
            <zero-one
              class="view-item"
              v-for="item in tableData"
              :key="item.name"
              :tableHead="tableHead"
              :tableBody="item.data"
              :name="item.name"
              @toNext="toNext"
            />
          </template>
          <template v-else>
            <two-chart
              class="view-item"
              name="passengerFlow"
              :head="pfHead"
              :body="pfBody"
              :option.sync="pfOption"
              :breadcrumb="breadcrumb"
            />
            <two-chart
              class="view-item"
              name="businessFlow"
              :head="bfHead"
              :body="bfBody"
              :option.sync="bfOption"
              :breadcrumb="breadcrumb"
            />
            <two-table
              class="view-item"
              name="operateData"
              :head="opHead"
              :body="opBody"
            />
            <two-table
              class="view-item"
              name="subsidiesData"
              :head="ssHead"
              :body="ssBody"
            />
          </template>
        </template>
        <template v-else>
          <Region
            class="view-item"
            v-for="(item, index) in regionData"
            :key="index"
            :head="item.head"
            :body="item.data"
            :name="item.name"
            @showDetail="showDetail"
          />
          <area-dialog
            ref="area"
            :name="diaData.name"
            :head="diaData.head"
            :body="diaData.body"
          />
        </template>
      </div>
    </div>
  </main>
</template>

<script>
import { strip } from '@/utils/numberPrecision'
import SiderBar from './components/siderBar'
import Map from './components/map/index'
import ZeroOne from './components/Yunying/levelZeroOne'
import TwoTable from './components/Yunying/levelTwoTable'
import TwoChart from './components/Yunying/levelTwoChart'
import Region from './components/Yingyun/index'
import AreaDialog from './components/Yingyun/areaDialog'
import { cityRank, operate, flow } from './components/Yunying/dealApi'
import { dealRegion } from './components/Yingyun/dealApi'
export default {
  name: 'MapMode',
  components: {
    SiderBar,
    Map,
    ZeroOne,
    TwoTable,
    TwoChart,
    Region,
    AreaDialog
  },
  data() {
    return {
      roleType: 0, // 0运营 1营运
      level: 0,
      refreshTime: '00:00',
      breadcrumb: [{
        name: '全国',
        map: 'china',
        adcode: 100000
      }], // 地图左上角的面包屑
      operateOptions: {
        platType: 0, // 0全部，8饿了么，9美团
        compareType: 1 // 1同比 2环比
      },
      tableHead: [
        {
          title: '排名',
          dataIndex: '$index',
          key: '$index'
        },
        {
          title: '城市',
          dataIndex: 'name',
          key: 'name'
        },
        {
          title: '今日数据',
          dataIndex: 'today',
          key: 'today'
        },
        {
          title: '上周数据',
          dataIndex: 'last',
          key: 'last'
        },
        {
          title: '同比上周',
          dataIndex: 'value',
          key: 'value',
          sort: true
        }
      ], // 运营第一级的数据, 一二级的表格头都是相同的，所以用一个就可以了，但是营运那边就不一样，每个表格头都是不同的，所以在regionData里面定义了一个head
      tableData: [
        {
          name: 'orders',
          data: []
        },
        {
          name: 'sales',
          data: []
        },
        {
          name: 'profit',
          data: []
        },
        {
          name: 'exposure',
          data: []
        },
        {
          name: 'shopConversion',
          data: []
        },
        {
          name: 'orderConversion',
          data: []
        },
        {
          name: 'subsidy',
          data: []
        }
      ], // 运营第一二级的表格数据
      opHead: [
        {
          title: '时间',
          dataIndex: 'time',
          key: 'time'
        },
        {
          title: '订单数',
          dataIndex: 'orders',
          key: 'orders'
        },
        {
          title: '毛利',
          dataIndex: 'profits',
          key: 'profits'
        },
        {
          title: '销售额',
          dataIndex: 'sales',
          key: 'sales'
        },
        {
          title: '曝光人数',
          dataIndex: 'push_num',
          key: 'push_num'
        }, {
          title: '入店转化率',
          dataIndex: 'take_rates',
          key: 'take_rates'
        }, {
          title: '下单转化率',
          dataIndex: 'place_order',
          key: 'place_order'
        }
      ], // 运营第三级的相关运营数据
      opBody: [],
      ssHead: [
        {
          title: '时间',
          dataIndex: 'time',
          key: 'time'
        },
        {
          title: '满减',
          dataIndex: 'manjian',
          key: 'manjian'
        },
        {
          title: '折扣',
          dataIndex: 'zhekoucai',
          key: 'zhekoucai'
        },
        {
          title: '赠品',
          dataIndex: 'zengpin',
          key: 'zengpin'
        },
        {
          title: '平台新客',
          dataIndex: 'plat_new',
          key: 'plat_new'
        },
        {
          title: '门店新客',
          dataIndex: 'shop_new',
          key: 'shop_new'
        },
        {
          title: 'c配',
          dataIndex: 'cp',
          key: 'cp'
        },
        {
          title: '券',
          dataIndex: 'quan',
          key: 'quan'
        },
        {
          title: '红包',
          dataIndex: 'hongbao',
          key: 'hongbao'
        },
        {
          title: '其他',
          dataIndex: 'other',
          key: 'other'
        },
        {
          title: '商家补贴',
          dataIndex: 'subside',
          key: 'subside'
        }
      ], // 运营第三级的补贴数据
      ssBody: [],
      pfHead: [
        {
          label: '曝光人数',
          value: 'push_num'
        },
        {
          label: '入店人数',
          value: 'take_rates'
        },
        {
          label: '下单人数',
          value: 'orders'
        },
        {
          label: '入店转化率',
          value: 'visit_rates'
        },
        {
          label: '下单转化率',
          value: 'place_order'
        }
      ], // 运营第三级的客流趋势数据设置，这里用作趋势图的筛选项
      pfBody: [],
      pfData: {}, // 要把后端返回来的数据保存下来，筛选累计分时和选项的时候有用
      pfOption: {
        type: 0,
        radio: 'push_num'
      }, // 客流趋势数据默认选中的筛选项
      bfHead: [
        {
          label: '营业额',
          value: 'sales'
        },
        {
          label: '有效订单',
          value: 'orders'
        },
        {
          label: '毛利',
          value: 'profits'
        }
      ], // 运营第三级的营业数据设置，这里用作趋势图的筛选项
      bfBody: [],
      bfData: {}, // 要把后端返回来的数据保存下来，筛选累计分时和选项的时候有用
      bfOption: {
        type: 0,
        radio: 'sales'
      }, // 营业数据默认选中的筛选项
      regionOptions: {
        dateType: 1, // 1昨日，2本月
        circleType: 2 // 1片区 2大区
      },
      regionData: [
        {
          name: 'allRank',
          head: [{
            title: '排名',
            dataIndex: '$index',
            key: '$index'
          }, {
            title: '区域',
            dataIndex: 'region',
            key: 'region'
          }, {
            title: '差评率',
            dataIndex: 'chapin',
            key: 'chapin'
          }, {
            title: '催单率',
            dataIndex: 'cuidan',
            key: 'cuidan'
          }, {
            title: '食品安全率',
            dataIndex: 'safe',
            key: 'safe'
          }],
          data: []
        },
        {
          name: 'badEva',
          head: [{
            title: '排名',
            dataIndex: '$index',
            key: '$index'
          }, {
            title: '区域',
            dataIndex: 'region',
            key: 'region'
          }, {
            title: '差评数',
            dataIndex: 'chapin_count',
            key: 'chapin_count'
          }, {
            title: '差评率',
            dataIndex: 'chapin',
            key: 'chapin'
          }],
          data: []
        },
        {
          name: 'urgOrd',
          head: [{
            title: '排名',
            dataIndex: '$index',
            key: '$index'
          }, {
            title: '区域',
            dataIndex: 'region',
            key: 'region'
          }, {
            title: '催单数',
            dataIndex: 'cuidan_count',
            key: 'cuidan_count'
          }, {
            title: '催单率',
            dataIndex: 'cuidan',
            key: 'cuidan'
          }],
          data: []
        },
        {
          name: 'foodSafe',
          head: [{
            title: '排名',
            dataIndex: '$index',
            key: '$index'
          }, {
            title: '区域',
            dataIndex: 'region',
            key: 'region'
          }, {
            title: '食品安全数',
            dataIndex: 'safe_count',
            key: 'safe_count'
          }, {
            title: '食品安全率',
            dataIndex: 'safe',
            key: 'safe'
          }],
          data: []
        },
        {
          name: 'proErr',
          head: [{
            title: '排名',
            dataIndex: '$index',
            key: '$index'
          }, {
            title: '区域',
            dataIndex: 'region',
            key: 'region'
          }, {
            title: '制作出错数',
            dataIndex: 'make_error_count',
            key: 'make_error_count'
          }, {
            title: '制作出错率',
            dataIndex: 'make_error',
            key: 'make_error'
          }],
          data: []
        },
        {
          name: 'score',
          head: [{
            title: '排名',
            dataIndex: '$index',
            key: '$index'
          }, {
            title: '区域',
            dataIndex: 'region',
            key: 'region'
          }, {
            title: '美团评分',
            dataIndex: 'mt_score',
            key: 'mt_score'
          }, {
            title: '饿了么评分',
            dataIndex: 'ele_score',
            key: 'ele_score'
          }, {
            title: '评分完成率',
            dataIndex: 'score_com',
            key: 'score_com'
          }],
          data: []
        },
        {
          name: 'storeAno',
          head: [{
            title: '排名',
            dataIndex: '$index',
            key: '$index'
          }, {
            title: '区域',
            dataIndex: 'region',
            key: 'region'
          }, {
            title: '店均门店异常数',
            dataIndex: 'excp_count',
            key: 'excp_count'
          }, {
            title: '门店异常率',
            dataIndex: 'excp',
            key: 'excp'
          }],
          data: []
        },
        {
          name: 'humanEff',
          head: [{
            title: '排名',
            dataIndex: '$index',
            key: '$index'
          }, {
            title: '区域',
            dataIndex: 'region',
            key: 'region'
          }, {
            title: '人员工资',
            dataIndex: 'gongzi',
            key: 'gongzi'
          }, {
            title: '人效',
            dataIndex: 'renxiao',
            key: 'renxiao'
          }],
          data: []
        },
        {
          name: 'checkStock',
          head: [{
            title: '排名',
            dataIndex: '$index',
            key: '$index'
          }, {
            title: '区域',
            dataIndex: 'region',
            key: 'region'
          }, {
            title: '食材占比',
            dataIndex: 'shicai',
            key: 'shicai'
          }, {
            title: '包材占比',
            dataIndex: 'baocai',
            key: 'baocai'
          }, {
            title: '标准毛利',
            dataIndex: 'biaozhun_maoli',
            key: 'biaozhun_maoli'
          }, {
            title: '营运毛利',
            dataIndex: 'yingyun_maoli',
            key: 'yingyun_maoli'
          }],
          data: []
        }
      ], // 营运数据表格设置
      diaData: {
        name: '',
        head: [],
        body: []
      }
    }
  },
  computed: {
    isAdmin() {
      const roleid = global.store.get('DATA_USER').role_id || null
      return roleid && roleid === 1
    },
    isYunying() {
      const roleid = global.store.get('DATA_USER').role_id || null
      return (roleid && roleid === 132) || (this.isAdmin && this.roleType === 0)
    },
    isYingyun() {
      const roleid = global.store.get('DATA_USER').role_id || null
      return (roleid && (roleid === 50 || roleid === 143)) || (this.isAdmin && this.roleType === 1)
    }
  },
  watch: {
    pfOption() {
      this.pfBody = this.deal(this.pfData, 'pf')
    },
    bfOption() {
      this.bfBody = this.deal(this.bfData, 'bf')
    },
    breadcrumb() {
      if (this.isYunying) {
        if (this.level <= 1) {
          this.getPlatformData()
        } else {
          const newItem = this.breadcrumb[this.breadcrumb.length - 1]
          if (Object.prototype.hasOwnProperty.call(newItem, 'shopid')) {
            this.getThirdTableData()
          } else {
            this.getThirdTableData()
          }
        }
      }
    }
  },
  mounted() {
    if (this.isYunying) {
      this.getPlatformData()
    }
    if (this.isYingyun) {
      this.getRegionData()
    }
  },
  methods: {
    // 获得运营首页表格的数据
    async getPlatformData() {
      const adcode = this.breadcrumb[this.breadcrumb.length - 1].adcode
      const {platType, compareType} = this.operateOptions
      const params = {
        plat_type: platType,
        compare_type: compareType,
        province: adcode
      }
      this.$muaLoadingShow()
      const {data} = await this.$https.get('city/rank', {params})
      this.$muaLoadingHide()
      this.refreshTime = data.pop()
      const res = cityRank(data)
      this.tableData.forEach(item => {
        item.data = res[item.name]
      })
    },
    // 获得第三级四个图表数据
    async getThirdTableData() {
      const lastItem = this.breadcrumb[this.breadcrumb.length - 1]
      let adcode = 0
      let shopid = 0
      if (Object.prototype.hasOwnProperty.call(lastItem, 'shopid')) {
        shopid = lastItem.shopid
        adcode = this.breadcrumb[this.breadcrumb.length - 2].adcode
      } else {
        adcode = lastItem.adcode
      }
      const {platType, compareType} = this.operateOptions
      let params = {
        plat_type: platType,
        city_code: adcode,
        compare_type: compareType
      }
      if (shopid) params.shop_code = shopid
      this.$muaLoadingShow()
      const flowData = await this.$https.get('flow', {params})
      const saleData = await this.$https.get('sale', {params})
      const operateData = await this.$https.get('operate', {params})
      const subsideData = await this.$https.get('subside', {params})
      this.$muaLoadingHide()
      Promise.all([flowData, saleData, operateData, subsideData]).then(res => {
        res.forEach((item, index) => {
          switch (index) {
            case 0: // 处理折线图数据
              this.pfData = flow(item.data, 'pf')
              this.pfBody = this.deal(this.pfData, 'pf')
              break
            case 1:
              this.bfData = flow(item.data, 'bf')
              this.bfBody = this.deal(this.bfData, 'bf')
              break
            case 2: // 处理运营表格数据
              this.opBody = operate(item.data)
              break
            case 3: // 处理补贴表格数据
              this.ssBody = operate(item.data)
              break
            default:
              break
          }
        })
      })
    },
    // 获得营运表格数据
    async getRegionData() {
      const {dateType, circleType} = this.regionOptions
      const params = {
        cycle: dateType,
        is_pianqu: circleType
      }
      this.$muaLoadingShow()
      const {data} = await this.$https.get('server', {params})
      this.$muaLoadingHide()
      const res = dealRegion(data)
      this.regionData.forEach(item => {
        item.data = res[item.name]
      })
    },
    chooseOption(type, data) {
      if (type === 0) {
        this.operateOptions.platType = data
      } else {
        this.operateOptions.compareType = data
        this.tableHead.forEach((item, index) => {
          if (item.key === 'last') item.title = data === 1 ? '上周数据' : '昨日数据'
          if (item.key === 'value') item.title = data === 1 ? '同比上周' : '环比昨日'
          this.tableHead.splice(index, 1, item)
        })
      }
      if (this.level <= 1) {
        this.getPlatformData()
      } else {
        this.getThirdTableData()
      }
    },
    changeOption(type, data) {
      if (type === 0) {
        this.regionOptions.dateType = data
      } else {
        this.regionOptions.circleType = data
      }
      this.refresh()
    },
    toNext(value) {
      const {code, name, provincecode, provincename, canClick} = value
      const province = {
        name: provincename,
        map: provincename,
        adcode: +provincecode
      }
      const city = {
        name: name,
        map: name,
        adcode: +code
      }
      this.breadcrumb = canClick ? this.breadcrumb.concat([province, city]) : this.breadcrumb.concat([province])
      this.level = canClick ? 2 : 1
    },
    // flow 趋势处理数据
    deal(data, tp) {
      const yesterday = []
      const today = []
      const radio = tp === 'pf' ? this.pfOption.radio : this.bfOption.radio
      const type = tp === 'pf' ? this.pfOption.type : this.bfOption.type // 0累计 1分时
      for (const key in data) {
        // key只有两个值，yesterday和today
        const element = data[key]
        if (type === 0) {
          const isInclude = ['visit_rates', 'place_order'].includes(radio) // visit_rates_all 和 place_order_all 是由服务器返回的处理好的值，直接使用就可以了
          if (isInclude) {
            element.forEach(item => {
              if (key === 'today') today.push(item[radio + '_all'])
              if (key === 'yesterday') yesterday.push(item[radio + '_all'])
            })
          } else {
            element.reduce((acc, cur) => {
              if (key === 'today') today.push(strip(acc + cur[radio] * 1))
              if (key === 'yesterday') yesterday.push(strip(acc + cur[radio] * 1))
              return strip(acc + cur[radio] * 1)
            }, 0)
          }
        } else {
          element.forEach(item => {
            if (key === 'today') today.push(item[radio])
            if (key === 'yesterday') yesterday.push(item[radio])
          })
        }
      }
      return [today, yesterday]
    },
    refresh() {
      if (this.roleType === 1) {
        this.$refs.area.close()
        this.getRegionData()
      } else {
        if (this.level <= 1) {
          this.getPlatformData()
        } else {
          this.getThirdTableData()
        }
      }
    },
    chooseRole(type) {
      this.roleType = type
      if (type === 1) {
        this.getRegionData()
      } else {
        if (this.level <= 1) {
          this.getPlatformData()
        } else {
          this.getThirdTableData()
        }
      }
    },
    // 弹框 - 显示营运弹框
    async showDetail(e) {
      const {dateType, circleType} = this.regionOptions
      const params = {
        cycle: dateType,
        is_pianqu: circleType,
        code: e.code
      }
      this.$muaLoadingShow()
      const {data} = await this.$https.get('server', {params})
      this.$muaLoadingHide()
      const res = dealRegion(data, 'dialog')
      this.diaData.name = e.region + this.$translate[e.tableName].ch
      this.diaData.head = this.regionData[e.tableIndex].head
      this.diaData.body = res[e.tableName]
      this.$refs.area.show()
    }
  }
}
</script>

<style lang="less" scoped>
.main {
  display: flex;
  justify-content: space-between;
  height: calc(100% - 65px - 4.625rem);
  padding-bottom: 2rem;
  box-sizing: border-box;
  .siderbar-left {
    width: 10%;
    height: 100%;
  }
  .content {
    width: 62%;
    height: 100%;
    border: 1.5rem solid transparent;
    border-image: url('http://datav.4008737773.com/static_img/border.png') 5%;
    box-sizing: border-box;
  }
  .siderbar-right {
    display: flex;
    flex-direction: column;
    width: 26%;
    height: 100%;
    .right-search {
      display: flex;
      justify-content: space-between;
      align-items: flex-start;
      flex-flow: row wrap;
      > div {
        display: inline-block;
        vertical-align: top;
        margin-bottom: 1rem;
      }
    }
    .view {
      flex: 1;
      padding-right: 0.25rem;
      // margin-top: 1rem;
      overflow-y: auto;
      box-sizing: border-box;
      &::-webkit-scrollbar{
        width: 0.25rem;
      }
      &::-webkit-scrollbar-thumb {
        background-color: #2EDBFF;
        border-radius: 0.25rem;
      }
      &-item {
        margin-bottom: 0.5rem
      }
      &-item:last-child {
        margin-bottom: 0;
      }
    }
    .refresh-image {
      width: 9px;
      height: 10px;
    }
  }
}
</style>