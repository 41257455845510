<template>
  <div class="mask" v-show="visible">
    <div class="data">
      <p class="font-18 color-fff">
        {{name}}
        <img @click="close" class="close" src="@/assets/close.png" alt="close">
      </p>
      <span class="color-2edbff font-10">Regional ranking</span>
      <div class="table mt-16 color-fff" v-if="body[0]">
        <mua-table :head="head" :data="body" background></mua-table>
      </div>
      <div class="mt-16 color-fff" v-else>暂无订单数据</div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'areaDialog',
  props: {
    head: {
      default: () => {
        return []
      },
      required: true,
      type: Array
    },
    body: {
      default: () => {
        return []
      },
      required: true,
      type: Array
    },
    name: {
      default: '',
      type: String,
      required: true
    }
  },
  data () {
    return {
      visible: false
    }
  },
  methods: {
    show () {
      this.visible = true
    },
    close () {
      this.visible = false
    }
  }
}
</script>

<style scoped lang="less">
.mask {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: rgba(0, 0, 0, 0.3);
  z-index: 199;
  .color-2edbff {
    color: #2EDBFF
  }
  .color-ff001a {
    color: #FF001A;
    font-weight: bold;
  }
  .mt-16 {
    margin-top: 16px;
  }
  .data {
    width: 41.25vw;
    padding: 0 16px 16px;
    background-image: linear-gradient(180deg, #12183D 0%, #05092D 100%);
    border: 2px solid #3B69AE;
    box-sizing: border-box;
    p {
      margin: 0;
      padding-top: 16px;
      .close {
        width: 16px;
        height: 16px;
        float: right;
        cursor: pointer;
      }
    }
    .table {
      width: 100%;
      .thead {
        display: flex;
        justify-content: space-between;
        background-image: linear-gradient(270deg, #01D18A 0%, #2483FF 100%);
        .th {
          height: 32px;
          line-height: 32px;
          flex: 1;
          text-align: center;
        }
      }
      .tbody {
        min-height: 180px;
        max-height: 500px;
        overflow-y: auto;
        &::-webkit-scrollbar{
          width: 2px;
        }
        &::-webkit-scrollbar-thumb {
          background-color: #2EDBFF;
          border-radius: 4px;
        }
        .tr {
          display: flex;
          justify-content: space-between;
          padding: 12px 0;
          &:hover {
            background-image: linear-gradient(270deg, #07866B 0%, #1C5AB3 100%);
          }
          .td {
            flex: 1;
            text-align: center;
            cursor: pointer;
            .icon {
              width: 15px;
              height: 15px;
              line-height: 15px;
              border-radius: 25px;
              background-image: linear-gradient(90deg, #2483FF 0%, #01D18A 100%);
            }
          }
        }
      }
    }
  }
}
</style>
